// Copyright 1999-2022. Plesk International GmbH. All rights reserved.

/* eslint-disable react/require-render-return */

import { Component } from './component';
import { DynamicPopupHint } from './dynamic-popup-hint';
import render from './render';
import LegacyEstimator from './password-estimator/legacy-estimator';
import { ExternalEstimator } from './password-estimator/external-estimator';
import StrengthLevels from './password-estimator/strength-levels';
import prepareUrl from './prepareUrl';

import './password-meter.less';

const strengthLevels = {
    VeryWeak: StrengthLevels.LEVEL_VERY_WEAK,
    Weak: StrengthLevels.LEVEL_WEAK,
    Mediocre: StrengthLevels.LEVEL_MEDIOCRE,
    Strong: StrengthLevels.LEVEL_STRONG,
    Stronger: StrengthLevels.LEVEL_STRONGER,
};

const levelVerdicts = {
    [StrengthLevels.LEVEL_VERY_WEAK]: 'verdictVeryWeak',
    [StrengthLevels.LEVEL_WEAK]: 'verdictWeak',
    [StrengthLevels.LEVEL_MEDIOCRE]: 'verdictMediocre',
    [StrengthLevels.LEVEL_STRONG]: 'verdictStrong',
    [StrengthLevels.LEVEL_STRONGER]: 'verdictStronger',
};

const levelClasses = {
    [StrengthLevels.LEVEL_VERY_WEAK]: 'password-strength-very-weak',
    [StrengthLevels.LEVEL_WEAK]: 'password-strength-weak',
    [StrengthLevels.LEVEL_MEDIOCRE]: 'password-strength-medium',
    [StrengthLevels.LEVEL_STRONG]: 'password-strength-strong',
    [StrengthLevels.LEVEL_STRONGER]: 'password-strength-very-strong',
};

export class PasswordMeter extends Component {
    _initConfiguration(config) {
        super._initConfiguration({
            tag: 'span',
            cls: 'password-strength',
            ...config,
        });
        this._passwordElement = document.getElementById(this._getConfigParam('observe', null));
        this._passwordElement.addEventListener('keyup', this._onChange.bind(this));
        // Temporary workaround for custom prototype's event 'plesk:passwordGenerated'
        this._passwordElement.addEventListener('dataavailable', this._onChange.bind(this));
        this._passwordElement.addEventListener('blur', this._onBlur.bind(this));

        this._requiredStrength = this._getConfigParam('requiredStrength', 'VeryWeak');
        this._passwordEstimator = this._getConfigParam('estimator', 'legacy') === 'external'
            ? new ExternalEstimator(prepareUrl(this._getConfigParam('estimationUrl', null)))
            : LegacyEstimator;
    }

    _initComponentElement() {
        super._initComponentElement();

        this._hintContainer = document.createElement('span');
        this._hintContainer.className = 'hint-inline hint-info';
        this._hint = document.createElement('span');
        this._hint.innerHTML = this.lmsg('hintInfo');
        this._hintContainer.appendChild(this._hint);

        this._progress = document.createElement('i');
        render(this._componentElement, this._progress);

        this._verdict = document.createElement('b');
        render(this._componentElement, this._verdict);
    }

    setProgress(value) {
        this._progress.setAttribute('style', `width: ${value}%`);
    }

    _onBlur() {
        const password = this._passwordElement.value;
        if ('' !== password) {
            this._passwordEstimator.estimatePassword(password).then(result => {
                this._updateError(result.level);
            });
        }
    }

    _onChange() {
        const password = this._passwordElement.value;

        this._updateVisibility();
        this._passwordEstimator.estimatePassword(password, this._estimateId).then(result => {
            if (result.password !== this._passwordElement.value) {
                return;
            }

            this._updateVisibility();
            this.setProgress(result.percentage);
            this._updateHint(result.suggestions);
            this._updateVerdict(result.level);
            this._updateColor(result.level);
            if ('' !== password) {
                this._updateError(result.level);
            }
        });
    }

    _updateVisibility() {
        if (this._passwordElement.value === '') {
            this._componentElement.style.display = 'none';
            this._hintContainer.style.display = 'none';
        } else {
            this._componentElement.style.display = '';
            this._hintContainer.style.display = '';
        }
    }

    _updateError(level) {
        const rowElement = this._passwordElement.closest('.form-row');
        if (!rowElement) {
            return;
        }
        if (level < strengthLevels[this._requiredStrength]) {
            rowElement.classList.add('error');
            rowElement.querySelector('.field-errors').innerHTML = `<span class="error-hint">${this.lmsg('errorHint')}</span>`;
            rowElement.querySelector('.field-errors').style.display = '';
        } else {
            rowElement.classList.remove('error');
            rowElement.querySelector('.field-errors').innerHTML = '';
            rowElement.querySelector('.field-errors').style.display = 'none';
        }
    }

    _updateColor(level) {
        const newClass = levelClasses[level];
        if (this._oldClass === newClass) {
            return;
        }
        if (this._oldClass) {
            this._componentElement.classList.remove(this._oldClass);
        }
        this._componentElement.classList.add(newClass);
        this._oldClass = newClass;
    }

    _updateHint(suggestions) {
        let description = '';
        if (suggestions.length) {
            description = `${this.lmsg('description')}<br/><ul>`;
            suggestions.forEach(suggestion => {
                description += `<li>${this.lmsg(suggestion)}</li>`;
            });
            description += '</ul>';
        } else {
            description += this.lmsg('yourPasswordIsStrong');
        }

        this._tooltip.setContent(description);
    }

    _updateVerdict(level) {
        this._verdict.innerHTML = this.lmsg(levelVerdicts[level]);
    }

    render() {
        super.render();

        if (!this._tooltip) {
            render(this._componentElement, this._hintContainer, 'after');
            this._tooltip = new DynamicPopupHint.Instance({
                title: this.lmsg('title'),
                waitMsg: '',
                url: '',
                placement: 'right',
                target: this._hint,
            });
        }
        this._onChange();
    }
}
