// Copyright 1999-2022. Plesk International GmbH. All rights reserved.

export class ExternalEstimator {
    constructor(estimationUrl) {
        this.estimationUrl = estimationUrl;
    }

    estimatePassword(password) {
        return new Promise(resolve => {
            new Ajax.Request(this.estimationUrl, {
                method: 'post',
                parameters: { password },
                onSuccess: response => {
                    const { level, suggestions } = response.responseJSON;

                    resolve({
                        password,
                        level,
                        percentage: 20 + (level * 20),
                        suggestions,
                    });
                },
            });
        });
    }
}
